.widget-social,
.widget-follow {
  display: flex;
  align-items: center;
  a {
    margin-left: 15px;
  }
  a:first-child {
    margin-left: 0px;
  }
  .bg-item {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      font-size: 24px;
      &.--facebook {
        padding: 2px;
      }
      &.--line {
        padding: 1px;
      }
      &.--tiktok {
        padding: 2px;
      }
      @media (max-width: 690px) {
        font-size: 34px;
      }
    }
    &:hover {
      svg {
        color: rgb(255, 255, 255);
      }
    }
  }
}
.related-news-desktop {
  width: 420px;
  margin: auto;
  min-height: unset;
  max-width: 100%;
}
.block-content {
  &.--small {
    .block-item {
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #eeeeee;

      &:nth-last-child(1) {
        border-bottom: none;
      }
    }
  }

  .block-list {
    padding-left: 0;
    li {
      list-style: none;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #eeeeee;

      &:nth-last-child(1) {
        border-bottom: none;
      }
    }
  }
}
.widget-content {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  min-height: 450px;

  .widget-content-title {
    min-height: 42px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 2px dotted #f6e000;
    margin-bottom: 20px;
    align-items: baseline;

    h3 {
      background: rgba(255, 230, 0, 0.75);
      color: #fff;
      width: fit-content;
      padding: 13px;
      border-radius: 10px 10px 0 0px;
      font-size: 14px;
      margin-bottom: -2px;
    }
    .related-news {
      // color: rgba(255, 230, 0, 0.75);
      color: #000000;
      background: unset;
      width: fit-content;
      padding: 13px;
      padding-left: 0;
      border-radius: 10px 10px 0 0px;
      font-size: 16px;
      margin-bottom: -2px;
    }
  }
}
