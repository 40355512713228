*,
*::before,
*::after {
  box-sizing: border-box;
}
html,
body {
  margin: 0px;
  padding: 0px;
  font-family: 'Prompt', sans-serif;
  letter-spacing: 0.2px;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(247, 247, 247, 0.9);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    //background-color: rgba(255, 230, 0, 0.7);
    background-color: gray;
  }

  p,
  h2,
  ul {
    margin-bottom: 0;
  }

  a {
    outline: none !important;
    text-decoration: none !important;
    color: rgb(0, 0, 0, 0.95);
  }

  section {
    padding: 38px 0;
  }
}

.scroll-to-top {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 20px;
    //color: rgba(255, 230, 0, 1);
    color: gray;
  }
}

abbr,
acronym,
address,
applet,
b,
big,
blockquote,
body,
caption,
center,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
font,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
i,
iframe,
ins,
kbd,
label,
legend,
li,
object,
ol,
p,
pre,
q,
s,
samp,
small,
span,
strike,
strong,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
tt,
u,
ul,
var {
  background: 0 0;
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  outline: 0;
}

.container {
  max-width: 1360px !important;
  width: 100%;
  margin: 0 auto;
  // padding-right: var(--bs-gutter-x, 0.75rem);
  // padding-left: var(--bs-gutter-x, 0.75rem);
  padding-left: 30px;
  padding-right: 30px;
}

.privacy-detail {
  line-height: 2.5;
  font-size: 16px;
  font-weight: 300;
  h1 {
    margin: 0 auto;
    font-size: 30px;
  }
  b,
  h2 {
    font-size: 24px;
    line-height: 1.5;
    font-weight: 500;
  }
  ul {
    margin-left: 50px;

    li {
      list-style-type: circle;
      display: list-item;
    }
  }
}

.img-scale-animate {
  overflow: hidden;

  &:hover {
    img {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
    }
    h3 {
      color: #cecece;
    }
  }
  img {
    -o-transition: all 1.5s ease-out;
    transition: all 1.5s ease-out;
    object-fit: cover;
  }
}

.resolution-image {
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
  display: block;
  img {
    position: absolute;
    object-fit: cover;
    // object-fit: contain;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
  }
}

.banner-special {
  width: 100%;
  img {
    max-width: 100%;
    width: 100%;
    margin-bottom: -7px;
  }
}

/* ----------------------------------- PWA ---------------------------------- */

.hide-pwa {
  display: none !important;
}
.pwa {
  display: none;
  position: fixed;
  width: 100%;
  z-index: 80;
  top: 10px;
  .class-pwa-popup {
    position: absolute;
    right: 10px;
    @media (max-width: 480px) {
      right: 50%;
      transform: translate(50%, 0);
    }
    .bg-pwa {
      color: rgba(256, 256, 256, 0.8);
      background-color: rgba(0, 0, 0, 0.9);
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px;
      padding: 8px 10px;
      align-items: center;
      border-radius: 3px;

      img {
        width: 50px;
        padding: 0 5px;
        vertical-align: middle;
        margin-right: 10px;
      }
      p {
        margin: 0;
        font-size: 14px;
        white-space: pre;
        padding: 5px;
        &:nth-child(2) {
          padding: 0 5px 5px 5px;
          font-size: 12px;
        }
      }
      .add-button,
      .add-button-close {
        padding: 5px;
        text-align: center;
        white-space: pre;
        cursor: pointer;
      }

      .install-close {
        border-left: 1px solid #363636;
        padding-left: 10px;
        margin-left: 10px;
      }

      .add-button {
        background-color: rgb(255, 230, 0, 1);
        color: rgb(0, 0, 0, 0.95);
        font-weight: bold;
        font-size: 14px;
        border-radius: 3px;
        padding: 6px 8px;
        margin-bottom: 5px;
        &:hover {
          color: #fff;
          background-color: rgb(66, 66, 66);
        }
      }

      .add-button-close {
        font-size: 12px;
        &:hover {
          color: rgb(255, 230, 0, 1);
        }
      }
    }
  }
}
/* -------------------------------------------------------------------------- */
.animation-arrow {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.animation-left {
  margin: 15px 20px 0 auto;

  width: 10px;
  height: 10px;
  transform: translateY(0px) translateX(0) rotate(-135deg);

  span {
    position: absolute;
    bottom: 10;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-left: 2px solid rgba(255, 230, 0, 0.7);
    border-bottom: 2px solid rgba(255, 230, 0, 0.7);
    animation: animate-left 1.5s linear infinite;
  }

  :nth-child(1) {
    transform: translate(6px, -6px);
    transition-delay: -0.4s;
  }
  :nth-child(2) {
    transform: translateX(0, 0);
    transition-delay: -0.2s;
  }
  :nth-child(3) {
    transform: translate(-6px, 6px);
    transition-delay: 0s;
  }
  @keyframes animate-left {
    0% {
      left: 5px;
      top: -5px;
      opacity: 0;
    }
    25% {
      left: 0px;
      top: 0px;
      opacity: 1;
    }
    100% {
      left: -5px;
      top: 5px;
      opacity: 0;
    }
  }
}

.animation-right {
  width: 10px;
  height: 10px;
  margin: 15px auto 0 20px;
  transform: translateY(0px) translateX(0) rotate(-135deg);

  span {
    position: absolute;
    bottom: 10;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-top: 2px solid rgba(255, 230, 0, 0.7);
    border-right: 2px solid rgba(255, 230, 0, 0.7);
    animation: animate-right 1.5s linear infinite;
  }

  :nth-child(1) {
    transform: translate(6px, -6px);
    transition-delay: -0.4s;
  }
  :nth-child(2) {
    transform: translateX(0, 0);
    transition-delay: -0.2s;
  }
  :nth-child(3) {
    transform: translate(-6px, 6px);
    transition-delay: 0s;
  }
  @keyframes animate-right {
    0% {
      left: -5px;
      top: 5px;
      opacity: 0;
    }
    25% {
      left: 0px;
      top: 0px;
      opacity: 1;
    }
    100% {
      left: 5px;
      top: -5px;
      opacity: 0;
    }
  }
}

.bg-section {
  padding: 38px 0;
  &.--gray {
    background-color: #f5f5f5;
  }
  &.--theme {
    background-color: #ffe000;
  }
  &.--blcak {
    background-color: rgba(0, 0, 0, 1);
  }
}

.show-desktop {
  display: block !important;
  &.--flex {
    display: flex !important;
  }
  @media (max-width: 992px) {
    display: none !important;
    &.--flex {
      display: none !important;
    }
  }
}

.show-mobile {
  display: block !important;
  &.--flex {
    display: flex !important;
  }
  @media (min-width: 992px) {
    display: none !important;
    &.--flex {
      display: none !important;
    }
  }
}

.error-content {
  text-align: center;
  margin-top: 50px;
  img {
    width: 100%;
    opacity: 0.6;
    max-width: 450px;
    @media (max-width: 480px) {
      max-width: 300px;
    }
  }
}

.title-pattern {
  display: table-cell;
  padding: 4px 0;
  .title-pattern-inner {
    background-image: url('https://hashmag.qodeinteractive.com/wp-content/themes/hashmag/assets/css/img/title-pattern.png');
    background-repeat: repeat;
    display: inline-block;
    height: 13px;
    width: 100%;
  }
}

.feed-bg {
  white-space: nowrap;
  display: table-cell;
  width: 1%;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 30px;
  color: #000;
  a {
    color: #212121;
    font-weight: 500;
  }
  &::after {
    content: '/';
    color: #212121;
    // margin: 0 8px 0 5px;
    margin: 0 8px;
  }
  &:hover {
    a {
      color: rgb(82, 82, 82);
    }
    &::after {
      color: rgb(82, 82, 82);
    }
  }
}
.section-head {
  padding-bottom: 12px;
}

.tag-pin {
  background-color: rgba(255, 230, 0, 1);
  padding: 0px 10px;
  left: 0;
  top: 0;
  color: #000;
  display: inline-block;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  text-transform: uppercase;
  p {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
  }
}
.video-card-share {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: rgba(255, 230, 0, 1) !important;
  // padding: 0px 6px !important;
  svg {
    font-size: 10px !important;
  }
}
.share-card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  z-index: 1;
  &:hover {
    .article-share {
      left: 38px;
      ul {
        li {
          opacity: 1;
        }
      }
    }
  }
}
.share-card,
.video-card-share {
  background-color: white;
  padding: 10px;
  left: 0;
  bottom: 0;
  transition: all 0.4s ease-in-out;
  z-index: 2;
  &:hover {
    background-color: rgba(255, 230, 0, 1);
  }
  .article-share {
    transition: all 0.6s ease-in-out;
    position: absolute;
    bottom: -6px;
    left: -60px;
    ul {
      list-style-type: none;
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        z-index: 0;
        opacity: 0;
      }
      li:nth-child(1) {
        transition: opacity 0.3s cubic-bezier(0.35, 0.7, 0.32, 0.9) 50ms, background-color 0.3s cubic-bezier(0.35, 0.7, 0.32, 0.9) 0s;
      }
      li:nth-child(2) {
        transition: opacity 0.3s cubic-bezier(0.35, 0.7, 0.32, 0.9) 0.1s, background-color 0.3s cubic-bezier(0.35, 0.7, 0.32, 0.9) 0s;
      }
      li:nth-child(3) {
        transition: opacity 0.3s cubic-bezier(0.35, 0.7, 0.32, 0.9) 0.15s, background-color 0.3s cubic-bezier(0.35, 0.7, 0.32, 0.9) 0s;
      }
      li:nth-child(4) {
        transition: opacity 0.3s cubic-bezier(0.35, 0.7, 0.32, 0.9) 0.2s, background-color 0.3s cubic-bezier(0.35, 0.7, 0.32, 0.9) 0s;
      }
    }
  }
}
// .share-card {
//   background-color: white;
//   padding: 5px 8px;

//   bottom: 0;
//   transition: all 0.4s ease-in-out;
//   z-index: 2;
//   &:hover {
//     background-color: rgba(255, 230, 0, 1);
//   }
//   .article-share {
//     transition: all 0.6s ease-in-out;
//     position: absolute;
//     bottom: 0;
//     left: -96px;
//     ul {
//       list-style-type: none;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       li {
//         opacity: 0;
//       }
//       li:nth-child(1) {
//         transition: opacity 0.3s cubic-bezier(0.35, 0.7, 0.32, 0.9) 50ms,
//         background-color 0.3s cubic-bezier(0.35, 0.7, 0.32, 0.9) 0s;
//       }
//       li:nth-child(2) {
//         transition: opacity 0.3s cubic-bezier(0.35, 0.7, 0.32, 0.9) 0.1s,
//           background-color 0.3s cubic-bezier(0.35, 0.7, 0.32, 0.9) 0s;
//       }
//       li:nth-child(3) {
//         transition: opacity 0.3s cubic-bezier(0.35, 0.7, 0.32, 0.9) 0.15s,
//           background-color 0.3s cubic-bezier(0.35, 0.7, 0.32, 0.9) 0s;
//       }
//       li:nth-child(4) {
//         transition: opacity 0.3s cubic-bezier(0.35, 0.7, 0.32, 0.9) 0.2s,
//           background-color 0.3s cubic-bezier(0.35, 0.7, 0.32, 0.9) 0s;
//       }
//     }
//   }
//   svg {
//     vertical-align: middle;
//     font-size: 20px;
//   }
// }

.text-search {
  font-family: 'Prompt', sans-serif;
}

.text-excerpt-2 {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.text-excerpt-1 {
  @extend .text-excerpt-2;
  -webkit-line-clamp: 1;
}

.text-excerpt-3 {
  @extend .text-excerpt-2;
  -webkit-line-clamp: 3;
}

.overflow-hidden {
  overflow: hidden;
}

.hide {
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-indent: 1000em;
}

.fetching {
  font-size: 20px;
  width: fit-content;
  white-space: pre;
  border-bottom: 2px solid gba(255, 230, 0);
  margin: 30px auto;
}

.show-landing-page {
  height: 100vh;
  overflow: hidden;
  width: 100vw;
  z-index: 99999999999;
}

.d-flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.align-item {
  align-items: center;
}

.row {
  // --bs-gutter-x: 1rem;
  // --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.2);
  margin-left: calc(var(--bs-gutter-x) * -0.2);
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.text-align-center {
  text-align: center;
}

.font-22 {
  font-size: 22px;
  font-weight: bold;
  line-height: 1.75;
  color: rgba(0, 0, 0, 0.95);
}

.w-100 {
  width: 100%;
  max-width: 100%;
}

.pr-30 {
  padding-right: 30px;
}
.pr-15 {
  padding-right: 15px;
}
.pb-30 {
  padding-bottom: 30px;
}

.pr-20 {
  padding-right: 20px;
}
.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.col-3 {
  flex: 0 0 auto;
  width: 25%;
}
.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}
.col-6 {
  flex: 0 0 auto;
  width: 50%;
}
.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
.col-9 {
  flex: 0 0 auto;
  width: 75%;
}
.col-12 {
  flex: 0 0 auto;
  width: 100%;
}
.pe-4 {
  padding: 0 15px !important;
}

@media (min-width: 767px) {
  .position-md-unset {
    position: unset !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-none {
    display: none !important;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .pe-md-4 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .mt-md-15 {
    margin-top: 15px !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 1023px) {
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .pr-lg-15 {
    padding-right: 15px !important;
  }
  .mt-lg-30 {
    margin-top: 30px !important;
  }
  .mt-lg-15 {
    margin-top: 15px !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.333333333%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

.p-0 {
  padding: 0;
}

.m-0 {
  margin: 0;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-30 {
  margin-bottom: 30px;
}

.pt-20 {
  padding-top: 20px;
}

.p-30 {
  padding: 30px 0;
}

.pe-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.m-auto {
  margin: auto;
}
.mt-15 {
  margin-top: 15px;
}
.mt-30 {
  margin-top: 30px;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.justify-center {
  justify-content: center;
}

// ads style

.close-box-ads {
  position: sticky;
  bottom: 0;
  z-index: 50;
  display: flex;
  justify-content: center;
  &.close {
    display: none;
  }
  .close-box {
    position: relative;
    &::before {
      content: 'X';
      cursor: pointer;
      position: absolute;
      top: -24px;
      right: 0;
      color: rgb(185, 183, 183);
      background-color: #363636;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      padding: 2px 7px;
    }
  }
}

#truehits_div {
  display: none;
  text-align: center;
  background: rgba(0, 0, 0, 1);
  padding-left: 10px;
}

.ads,
.ads-inarticle {
  width: 100%;
  height: auto;
  margin: 15px auto !important;
  text-align: center;
  overflow-x: hidden;
  img,
  iframe {
    max-width: 100%;
    display: block;
    margin: auto;
  }
}

.ads-billboard,
.ads-postartice1,
.ads-sticky {
  // min-height: 100px;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
}

.default-billboard {
  position: relative;
  overflow: hidden;
  margin: 30px auto;
  min-height: 250px;
  @media (max-width: 475px) {
    min-height: 300px;
  }
  iframe {
    margin: auto;
  }
}

.ads-postarticle {
  position: sticky;
  bottom: 0;
}

// .position-sticky-left-content {
//   position: sticky;
//   top: 97px;
//   z-index: 14;
//   @media (max-width: 1023px) {
//     position: fixed;
//     top: 65%;
//     right: 0;
//     transform: translate(0, -65%);
//   }
// }

// .position-sticky-left-content-js {
//   position: sticky;
//   top: 126px;
//   z-index: 14;
//   @media (max-width: 1023px) {
//     position: fixed;
//     right: 0%;
//     transform: translate(0, 0);
//   }
// }

.back-drop {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 21.38px, rgba(0, 0, 0, 0.19) 0px 32px 64px;
  width: max-content;
  padding: 5px 10px;
  border-radius: 5px;
}
blockquote {
  position: relative;
  margin-bottom: 15px;

  p,
  span,
  strong {
    padding: 15px;
    margin: 10px;
    @media (max-width: 768px) {
      padding: 40px 20px;
      margin-bottom: 15px;
    }
  }
  @media (max-width: 480px) {
    margin: 0;
    margin-bottom: 15px;
  }
}

blockquote:before {
  content: open-quote;
  font-size: 50px;
  height: 20px;
  position: absolute;
  top: -10px;
  left: 10px;
  color: #ffe000;
}

blockquote:after {
  content: close-quote;
  justify-content: end;
  display: flex;
  font-size: 50px;
  position: absolute;
  bottom: -30px;
  right: 10px;
  color: #ffe000;
}
