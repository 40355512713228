.border-item {
  border-bottom: 1px solid rgba(120, 120, 120, 0.1);
  padding: 0 0 10px 0;
  margin-bottom: 10px;
  &:last-child {
    border: none;
    padding: none;
  }
}

.headiing-line {
  position: relative;
  display: flex;
  align-items: center;
  margin: auto;
  padding: 15px 0 5px 0;
  width: 90%;
  @media (min-width: 768px) {
    width: 70%;
  }
  span {
    padding: 0 15px;
    font-family: "Sarabun", sans-serif;
    font-size: 16px;
    line-height: 1.85;
    font-weight: 400;
    letter-spacing: 0.75px;
    word-break: break-word;
    font-display: swap;
  }
}
.divider {
  flex: 1 0 0;
  content: " ";
  border-top: 1px solid;
  border-color: rgba(255, 230, 0);
  .--left {
    margin: 0 1em 0 0;
  }
  .--right {
    margin: 0 0 0 1em;
  }
}

.fit-content {
  display: block;
  text-align: center;
  width: 100%;
  line-height: 1.75;
  max-width: fit-content;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pageview {
  @extend .flex-center;
  line-height: 1.45;
  font-size: 10px;
  font-weight: 300;
  color: #6f6f6f;
  svg {
    margin-right: 5px;
    color: #6f6f6f;
  }
}

.read-more {
  font-weight: 500;
  display: none;
  background: transparent;
  // border-radius: 2px ;
  padding: 2px 8px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  span {
    font-size: 13px !important;
  }
  svg {
    margin-bottom: -3px;
  }
  &:hover {
    color: rgba(255, 230, 0);
    // color:  rgba(255, 230, 0);
  }
}

.blurb {
  font-size: 14px;
  line-height: 1.75;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.8);
}

.byline {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 300;
  color: #6f6f6f;
  svg {
    font-size: 12px;
    color: #6f6f6f;
  }
}

.meta {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.date-time {
  // margin-right: 5px;
  span {
    padding-right: 5px;
    font-size: 12px;
    font-weight: 300;
    color: #6f6f6f;
  }
  display: flex;
  align-items: center;
  justify-content: flex-start;
  svg {
    font-size: 10px;
    color: #626262;
  }
  // span {
  //// padding-left: 5px;
  // }
}

.play-btn {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  left: 50%;
  display: block;
  z-index: 0;
  transition: all 0.5s ease-out;

  svg {
    color: rgba(255, 230, 0);
    font-size: 16px;
    margin: 0 0 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &::after {
    content: "";
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    box-shadow: #9e9e9e 0px 0px 5px;
    width: 45px;
    height: 45px;
    border-radius: 50px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    left: 50%;
    top: 45%;
    right: 0;
    z-index: -1;
  }
  &:hover {
    svg {
      color: rgba(0, 0, 0, 0.8);
    }
    &::after {
      background: rgba(255, 230, 0.5);
    }
  }
}
.play-btn-video {
  position: absolute;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  width: 45px;
  height: 45px;
  background: radial-gradient(rgba(255, 230, 0, 0.7) 60%, rgba(255, 230, 0, 0.8) 62%);
  border-radius: 50%;
  display: block;
  box-shadow: 0px 0px 25px 3px rgba(0, 0, 0, 0.9);
  z-index: 11;
  @media (max-width: 475px) {
    bottom: 50%;
    right: 50%;
    width: 40px;
    height: 40px;
    transform: translate(50%, 50%);
  }
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    transform-origin: center center;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 20px solid rgba(0, 0, 0, 0.7);
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    @media (max-width: 475px) {
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 18px solid rgba(0, 0, 0, 0.7);
    }
  }
  /* pulse wave */
  &:before {
    content: "";
    position: absolute;
    width: 150%;
    height: 150%;
    animation-delay: 0s;
    animation: pulsate1 2s;
    animation-direction: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(255, 230, 0, 0.9);
    top: -25%;
    left: -25%;
    background: rgba(255, 230, 0, 0.75);
  }

  @-webkit-keyframes pulsate1 {
    0% {
      transform: scale(0.6);
      opacity: 1;
      box-shadow: inset 0px 0px 25px 3px rgba(255, 230, 0, 0.75), 0px 0px 25px 10px rgba(255, 230, 0, 0.75);
    }
    100% {
      transform: scale(1);
      opacity: 0;
      box-shadow: none;
    }
  }

  @keyframes pulsate1 {
    0% {
      transform: scale(0.6);
      opacity: 1;
      box-shadow: inset 0px 0px 25px 3px rrgba(255, 230, 0, 0.75), 0px 0px 25px 10px rgba(255, 230, 0, 0.75);
    }
    100% {
      transform: scale(1);
      opacity: 0;
      box-shadow: none;
    }
  }
}
// .background-article {
//   background: #f5f5f5;
//   padding: 1rem;
//   border-radius: 0.25rem;
//   &.--white {
//     background: #fff;
//   }
// }

.svgll {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 99vw;
  height: 99vh;
  max-width: 99vw;
  max-height: 99vh;
}
